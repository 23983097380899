
import { Typewriter } from 'react-simple-typewriter'

export default function SectionTwo({sectionRefs}){
    const words = [
        "The future of our planet depends on us.",
        "Sustainability projects are being developed in hindsight.",
        "There is lack of transparency, structure, investment and security in the carbon market.",
      
    ];

    // if this ref is visible on screen
    // then only show the animation

    return (
        <div ref={sectionRefs.sectionTwo} style={{width : '100vw',height : '100vh'}} className='d-flex align-items-center text-light'>
            <div className='container' style={{height : '100%'}}>
                <div className='row mt-5' style={{height : '100%'}}>   
                    <div className='col-6 mt-5' style={{height : '100%'}}>
                        <div className='d-flex flex-column justify-content-between' style={{height : '80%'}}>
                            <div>
                                <h1 className='text-info'>The window for opportunities to act is closing fast</h1>
                                <p><Typewriter words={words} cursor={true} delaySpeed={200} deleteSpeed={5} loop={0} /></p>
                            </div>
                            <div className='row bg-info p-1' style={{height: '30vh'}}>
                                <div className='col-4' style={{ backgroundImage: 'url(/stockimages/ForestFire.jpg)', backgroundSize : 'cover',backgroundPositionY : 'bottom', height: '100%' }}></div>
                                <div className='col-4' style={{ backgroundImage: 'url(/stockimages/Pollution.jpg)', backgroundSize : 'cover',backgroundPositionY : 'bottom', height: '100%' }}></div>
                                <div className='col-4' style={{ backgroundImage: 'url(/stockimages/drought.jpeg)', backgroundSize : 'cover',backgroundPositionY : 'bottom', height: '100%' }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

import { useState } from 'react';

const topics = {
    'Offset Project': [
      {
      subTopic: 'Risk Index',
      about: "An index that assesses the risk profiles and levels of offset projects, helping investors make informed investment decisions and insurance companies accurately determine premiums for insurance policies to ensure appropriate coverage.",
      },
      {
      subTopic: 'CarbonSure Index',
      about: "An index that measures the reliability and credibility of offset projects based on factors like feasibility, monitoring protocols, and validation processes to provide a clear indication of the project's effectiveness in achieving carbon reduction goals.",
      },
  ],
  'Investors': [
    {
    subTopic: 'Credibility Score',
    about: "A score that assesses an investor's reputation, trustworthiness, and track record in carbon offset projects. It considers factors like past performance, financial stability, ethical practices, and transparency.",
    },
    {
    subTopic: 'Impact Quotient',
    about: "A score that measures an investor's commitment to positive environmental and social impact through carbon offset investments based on factors like investment scale, success rate, sustainable finance expertise, technology assessment, and risk appetite.",
    },
  ],

  'Project Developers': [
      {
      subTopic: 'Credit Score',
      about: "A score that assesses the project developer's financial stability, reliability, and creditworthiness based on factors such as past performance, financial history, risk indicators, and helps stakeholders evaluate their suitability for investments or partnerships.",
      },
      {
      subTopic: 'FutureFit Score',
      about: "A score that assesses the readiness and adaptability of project developers for the future. It goes beyond traditional credit scoring and focuses on evaluating the developer's ability to align with emerging trends, technologies, and sustainability goals.",
      },
  ]
    
    
    // 'Market Buyers': [
    //     {
    //     subTopic: 'Ecosure Index',
    //     about: "An index that measures the credibility and trustworthiness of buyers in the carbon market. It evaluates various factors, such as their track record, compliance with industry standards, transparency in reporting, and commitment to sustainable practices.",
    //     },
    // ],
}


const TabContent = ({ content }) => (
    <div className='px-3'>
      {content.map((item, index) => (
        <div key={index} className="mb-4">
          <h3 className='text-info text-brave'>{item.subTopic}</h3>
          <span>{item.about}</span>
        </div>
      ))}
    </div>
  );
  
  const TopicsTabs = ({ topics }) => {
    const [activeTopic, setActiveTopic] = useState(Object.keys(topics)[0]);
  
    return (
      <div>
        <ul className="nav nav-tabs">
          {Object.keys(topics).map((topic, index) => (
            <li className="nav-item" key={index}>
              <button
                className={`text-brave nav-link ${activeTopic === topic ? 'bg-primary text-light' : 'text-light'}`}
                onClick={() => setActiveTopic(topic)}
              >
                {topic}
              </button>
            </li>
          ))}
        </ul>
        <div className='py-3' style={{backgroundColor : 'rgba(0,150,255,0.4)'}}>
          {topics[activeTopic] && <TabContent content={topics[activeTopic]} />}
        </div>
      </div>
    );
  };

export default function SectionFour({sectionRefs}){
    return (
        <div className='text-light' ref={sectionRefs.sectionFour} style={{width : '100vw',height : '100vh',position : 'relative',zIndex : 4}}>
            <div className='container d-flex align-items-center' style={{height : '100%'}}>
                <TopicsTabs topics={topics} />
            </div>
        </div>
    )
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/Designer.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Brave'; /* You can use any name you prefer */
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  /* You can also include other font formats like woff, woff2 for better compatibility */
}


h1,h4{
  font-family : 'Brave'
}

.text-brave {
  font-family : 'Brave'
}


h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;


}

h3 {
  font-size: 1.2rem;


}

h4 {
  font-size: 1rem;


}

p {
  font-size: 1.5rem;

}






@media screen and (max-width: 1000px) {
  h1 {
    font-size:  1.5rem;
  }
  h2 {
    font-size : 1.3rem;
  }
  h3 {
    font-size : 1.1rem 
  }
  h4 {
    font-size : 0.9rem
  }
  p {
    font-size : 0.9rem
  }
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB,EAAE,oCAAoC;EAC1D,+DAAqD;EACrD,sFAAsF;AACxF;;;AAGA;EACE;AACF;;AAEA;EACE;AACF;;;AAGA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;;;AAGnB;;AAEA;EACE,iBAAiB;;;AAGnB;;AAEA;EACE,eAAe;;;AAGjB;;AAEA;EACE,iBAAiB;;AAEnB;;;;;;;AAOA;EACE;IACE,kBAAkB;EACpB;EACA;IACE,kBAAkB;EACpB;EACA;IACE;EACF;EACA;IACE;EACF;EACA;IACE;EACF;AACF","sourcesContent":["@font-face {\n  font-family: 'Brave'; /* You can use any name you prefer */\n  src: url('../public/Designer.otf') format('truetype');\n  /* You can also include other font formats like woff, woff2 for better compatibility */\n}\n\n\nh1,h4{\n  font-family : 'Brave'\n}\n\n.text-brave {\n  font-family : 'Brave'\n}\n\n\nh1 {\n  font-size: 2rem;\n}\n\nh2 {\n  font-size: 1.5rem;\n\n\n}\n\nh3 {\n  font-size: 1.2rem;\n\n\n}\n\nh4 {\n  font-size: 1rem;\n\n\n}\n\np {\n  font-size: 1.5rem;\n\n}\n\n\n\n\n\n\n@media screen and (max-width: 1000px) {\n  h1 {\n    font-size:  1.5rem;\n  }\n  h2 {\n    font-size : 1.3rem;\n  }\n  h3 {\n    font-size : 1.1rem \n  }\n  h4 {\n    font-size : 0.9rem\n  }\n  p {\n    font-size : 0.9rem\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* Styles for all screen sizes */
img {
    /* Common styles for both mobile and PC */
    width: auto;
  }
  
  h4 {
    /* Common styles for both mobile and PC */
    font-size: 1rem;
  }
  
  li {
    /* Common styles for both mobile and PC */
    font-size: 0.5rem;
  }
  
  /* Media query for mobile devices */
  @media (max-width: 767px) {
    img {
      height: 2rem;
    }
  
    h4 {
      font-size: 1rem;
    }
  
    li {
      font-size: 0.5rem;
    }
  }
  
  /* Media query for desktop devices */
  @media (min-width: 768px) {
    img {
      height: 4rem;
    }
  
    h4 {
      font-size: 1.8rem;
    }
  
    li {
      font-size: 1rem;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/SectionThree.css"],"names":[],"mappings":";AACA,gCAAgC;AAChC;IACI,yCAAyC;IACzC,WAAW;EACb;;EAEA;IACE,yCAAyC;IACzC,eAAe;EACjB;;EAEA;IACE,yCAAyC;IACzC,iBAAiB;EACnB;;EAEA,mCAAmC;EACnC;IACE;MACE,YAAY;IACd;;IAEA;MACE,eAAe;IACjB;;IAEA;MACE,iBAAiB;IACnB;EACF;;EAEA,oCAAoC;EACpC;IACE;MACE,YAAY;IACd;;IAEA;MACE,iBAAiB;IACnB;;IAEA;MACE,eAAe;IACjB;EACF","sourcesContent":["\n/* Styles for all screen sizes */\nimg {\n    /* Common styles for both mobile and PC */\n    width: auto;\n  }\n  \n  h4 {\n    /* Common styles for both mobile and PC */\n    font-size: 1rem;\n  }\n  \n  li {\n    /* Common styles for both mobile and PC */\n    font-size: 0.5rem;\n  }\n  \n  /* Media query for mobile devices */\n  @media (max-width: 767px) {\n    img {\n      height: 2rem;\n    }\n  \n    h4 {\n      font-size: 1rem;\n    }\n  \n    li {\n      font-size: 0.5rem;\n    }\n  }\n  \n  /* Media query for desktop devices */\n  @media (min-width: 768px) {\n    img {\n      height: 4rem;\n    }\n  \n    h4 {\n      font-size: 1.8rem;\n    }\n  \n    li {\n      font-size: 1rem;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/spacecrusade.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'SpaceFont'; /* You can use any name you prefer */
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    /* You can also include other font formats like woff, woff2 for better compatibility */
}`, "",{"version":3,"sources":["webpack://./src/Navbar.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB,EAAE,oCAAoC;IAC9D,+DAAyD;IACzD,sFAAsF;AAC1F","sourcesContent":["@font-face {\n    font-family: 'SpaceFont'; /* You can use any name you prefer */\n    src: url('../public/spacecrusade.ttf') format('truetype');\n    /* You can also include other font formats like woff, woff2 for better compatibility */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

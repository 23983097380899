import './SectionThree.css'

function ImageWithDescriptionComponent({content}){

    const {heading,bullets,icon} = content
    return (
        <div className='d-flex mb-1'>
            <div className='d-flex justify-content-center p-3' style={{backgroundColor : 'rgba(0,150,250,0.4)'}}>
                <img src={icon} />
            </div>
            <div className='col-9 ps-2' style={{backgroundColor : 'rgba(0,150,250,0.2)'}}>
                <h4>{content.heading}</h4>
                <ul className='d-none d-lg-block'>
                    {
                        content.bullets.map((item,index)=>{
                            return (
                                <li key={index}>{item}</li>
                            )
                        }
                        )
                    }
                </ul>
            </div>
        </div>
    )
}

function SectionThreeContent(){

    const content = [
            {
                'heading' : 'Risk Modelling For Certainity',
                'bullets' : [
                    'Mathematical Models',
                    'Benchmarking with Various Standards',
                    'Historical Data Modelling with Climate Impact'
                ],
                'icon' : '/icons/risk.png'
            },
            {
                'heading' : 'Remote Sensing & AI for Reliability',
                'bullets' : [
                    'Multi-modal Satellite Imagery Processing',
                    'Intelligence fusion from multi sensory input',
                    'Ground truth validation - community'
                ],
                'icon' : '/icons/satellite.png'

            },
            {
                'heading' : 'Cloud Dashboard for Transparency',
                'bullets' : [
                    'Transparency for Project Status',
                    'Live Monitoring for Development',
                    'Connected Infra for Easy Data Aggregation & Reporting'
                ],
                'icon' : '/icons/dashboard.png'
            },{
                'heading' : 'Integrity Ratings for Credibility',
                'bullets' : [
                    'Integrity Score Basis of Standards (e.g. ICVCM)',
                    'Dynamic Rating Indices with VCMI,CORSIA, etc.',
                    'Blockchain-Based Data Aggregation for Faster Claims'
                ],
                'icon' : '/icons/integrity.png'
            }
    ]


    return (
        <div className='row mb-5 ' >
            <h4 className='text-light d-none d-lg-block' style={{backgroundColor : 'rgba(0,150,250,0.4)'}}>Driving Sustainable Impact and Maximizing Returns in Carbon Markets with Cutting-Edge Technology</h4>

            {
                content.map((item,index)=>{
                    return (
                        <div key={index} className='col-6 px-0'>
                            <ImageWithDescriptionComponent  content={item} />
                        </div>
                    )
                })
            }
        </div>
    )
}

export default function SectionThree({sectionRefs}){

    return (
        <div ref={sectionRefs.sectionThree} style={{width : '100vw',height : '100vh',position : 'relative',zIndex : 4}} className='d-flex flex-column justify-content-end'>
            <div className='container text-light'>
                <SectionThreeContent />
            </div>
        </div>
    )
}
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useRef } from 'react';
import { Canvas } from 'react-three-fiber';
import { TextureLoader,RepeatWrapping } from 'three';
import { useFrame } from 'react-three-fiber';
import { MathUtils } from 'three';
import {NormalBlending} from 'three';
import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo';
import SectionThree from './SectionThree';
import SectionFour from './SectionFour';
import FireAnimation from './Fire';
import Footer from './Footer';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './App.css';

// import bootstrap



const earthPositionConfigs = {
  'sectionOne' : {
    position : [3,-3,4],
    scale :[0.9,1.3,1]
  },
  'sectionTwo' : {
    position : [3.5,0,4],
    scale :[1,1,1]
  },
  'sectionThree' : {
    position : [0,0,0],
    scale : [0.8,0.8,0.8]
  },
  'sectionFour' : {
    position : [0,3,2],
    scale : [2,1,2]
  }
}


const satellitePositionConfigs = {
  'sectionOne' : {
    position : [1,1,1],
    scale : [1,1,1]
  },
  'sectionTwo' : {
    position : [3.5,0,4],
    scale : [0,0,0]
  },
  'sectionThree' : {
    position : [-2,2,1],
    scale : [0,0,0]
  },
  'sectionFour' : {
    position : [0,3,2],
    scale : [0,0,0]
  }
}

function lerpingHelper(targetPosition, targetScale, ref, totalTime = 1000) {
  const startTime = performance.now();
  const currentPosition = [ref.current.position.x, ref.current.position.y, ref.current.position.z];
  const currentScale = [ref.current.scale.x, ref.current.scale.y, ref.current.scale.z];
  function animateLoop(timestamp) {
      const currentTime = timestamp - startTime;
      const t = Math.min(currentTime / totalTime, 1); // Interpolation factor

      const lerpingPositionX = MathUtils.lerp(currentPosition[0], targetPosition[0], t);
      const lerpingPositionY = MathUtils.lerp(currentPosition[1], targetPosition[1], t);
      const lerpingPositionZ = MathUtils.lerp(currentPosition[2], targetPosition[2], t);

      const lerpingScaleX = MathUtils.lerp(currentScale[0], targetScale[0], t);
      const lerpingScaleY = MathUtils.lerp(currentScale[1], targetScale[1], t);
      const lerpingScaleZ = MathUtils.lerp(currentScale[2], targetScale[2], t);

      ref.current.position.set(lerpingPositionX, lerpingPositionY, lerpingPositionZ);
      ref.current.scale.set(lerpingScaleX, lerpingScaleY, lerpingScaleZ);

      if (t < 1) {
          requestAnimationFrame(animateLoop);
      }
  }

  requestAnimationFrame(animateLoop);
}


// Inside your EarthModel component


const updateOpacity = (ref, currentOpacity, targetOpacity) => {

  // for every 1ms increase opacity by 0.006 till it reaches 1
  const opacityStep = 0.006;
  // wait for 0.2 seconds
  setTimeout(() => {

  const opacityInterval = setInterval(() => {
      if (currentOpacity >= targetOpacity) {
          clearInterval(opacityInterval);
      } else {
          currentOpacity += opacityStep;
          ref.current.material.opacity = currentOpacity;
      }
  })

  }, 800);
};


function EarthModel({sectionRefs}) {
  
  const earthRef = useRef();
  const satelliteRef = useRef();
  const satelliteWithTreeRef = useRef();
  const earthTextureRef = useRef();
  const ratingCardRef = useRef()
  const textureLoader = new TextureLoader();

  // if device is mobile

  const earthTexturePath = ()=>{
    if(window.innerWidth > 1000){
      return '/nasa.jpg'
    }
    return '/nasa_low.jpg'
  }
  
  
  
  const earthTexture = textureLoader.load(earthTexturePath());
  
  
  const satelliteTexture = textureLoader.load('/satellite.png');
  const satelliteWithTreeTexture = textureLoader.load('/satellitewithtrees.png');
  const ratingCardTexture = textureLoader.load('/ratingcard.png');


  let currentSectionId = null;

  const handleScroll = () => {
    let nextSectionId = null;

    for (const sectionId in sectionRefs) {
      const sectionRef = sectionRefs[sectionId];
      if (sectionRef.current) {
        const rect = sectionRef.current.getBoundingClientRect();
        if (rect.top <= window.innerHeight * 0.5 && rect.bottom >= window.innerHeight * 0.5) {
          nextSectionId = sectionId;
          if(currentSectionId !== nextSectionId){
            // console.log(currentSectionId,'-->',nextSectionId)
            if(nextSectionId=='sectionTwo'){
              sectionRefs.sectionTwo.current.firstChild.classList.remove('d-none')
              earthRef.current.rotation.y = Math.PI*1.3
              
              satelliteWithTreeRef.current.material.opacity = 0;
              ratingCardRef.current.material.opacity = 0;
              satelliteWithTreeRef.current.visible = false;
              ratingCardRef.current.visible = false;
   
              document.querySelector('#brand').classList.remove('d-none')
              
              
            }
            if(nextSectionId=='sectionThree'){
              // gradually increase opacity of satelliteWithTreeRef
              // updateOpacity(satelliteWithTreeRef, 0, 1); // Call this to start the opacity transition
              earthRef.current.rotation.y = Math.PI
              satelliteWithTreeRef.current.visible = true;
              ratingCardRef.current.visible = true;
              updateOpacity(satelliteWithTreeRef, 0, 1); // Call this to start the opacity transition
              updateOpacity(ratingCardRef, 0, 1);
              sectionRefs.sectionTwo.current.firstChild.classList.add('d-none')
              document.querySelector('#brand').classList.add('d-none')
            }
            else{
              // console.log('removing opacity')
              // gradually decrease opacity of satelliteWithTreeRef
              // updateOpacity(satelliteWithTreeRef, 1,0); 
              satelliteWithTreeRef.current.visible = false;
              ratingCardRef.current.visible = false;
              satelliteWithTreeRef.current.material.opacity = 0;
              ratingCardRef.current.material.opacity = 0;
              document.querySelector('#brand').classList.remove('d-none')
              
            }
            
            lerpingHelper(earthPositionConfigs[nextSectionId].position,earthPositionConfigs[nextSectionId].scale,earthRef)
            lerpingHelper(satellitePositionConfigs[nextSectionId].position,satellitePositionConfigs[nextSectionId].scale,satelliteRef)
            currentSectionId = nextSectionId;
          }

          break; // No need to continue checking other sections once active section is found
        }
      }
    }


  };


  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    earthRef.current.rotation.y = Math.PI*1.4
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    
  }, []);

  useFrame(() => {
    // Rotate the Earth
    if(currentSectionId=='sectionTwo'){
      earthRef.current.rotation.y += 0
    }
    else{
      earthRef.current.rotation.y += 0.001;
    }
    
  });

  return (
    <>
    {/* satellite comes on top of earth */}
    
    <mesh ref={earthRef} position={earthPositionConfigs['sectionOne'].position} scale={earthPositionConfigs['sectionOne'].scale}>
      <sphereGeometry args={[3, 128, 128]} />
      <meshStandardMaterial ref={earthTextureRef} map={earthTexture} />
    </mesh>
    
    <mesh ref={satelliteRef} position={satellitePositionConfigs['sectionOne'].position} scale={satellitePositionConfigs['sectionOne'].scale}>
        <planeGeometry args={[1, 0.7]} />
        <meshBasicMaterial map={satelliteTexture} />
    </mesh>
    <mesh ref={satelliteWithTreeRef} position={[-1,0.9,3]} scale={[1,1,1]}>
        <planeGeometry args={[2, 1.2]} />
        <meshLambertMaterial transparent={true} map={satelliteWithTreeTexture}  opacity={0} />
    </mesh>
    <mesh ref={ratingCardRef} position={[1.3,0.9,3]} scale={[1,1,1]}>
        <planeGeometry args={[1.3, 1]} />
        <meshBasicMaterial transparent={true}  map={ratingCardTexture} blending={NormalBlending}  opacity={0} />
    </mesh>
    
    <FireAnimation sectionRefs = {sectionRefs}/>

    </>
  );
}




function EarthScene({sectionRefs}){


  // after 2 seconds set isLoading to false
  React.useEffect(() => {
    setTimeout(() => {
      const loadingScreenDom = document.getElementById('loadingScreenId')
      // remove this element
      loadingScreenDom.remove()
      
    }, 2000)
  }, [])

  return (
    <>
    <div id='loadingScreenId' className='d-flex align-items-center justify-content-center bg-dark' style={{height : '100vh',width : '100vw',position : 'fixed',zIndex : 10}}>
      <h3 className='text-info text-center'>
      <div className="spinner-border text-info" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      </h3>
    </div>
    <Canvas style={{height : '100vh',width : '100vw',position : 'fixed',zIndex : 2}}>
        <ambientLight intensity={15} />
        <pointLight position={[10, 10, 10]} intensity={2} />
        <EarthModel sectionRefs={sectionRefs} />
    </Canvas>
    </>
  )
}

function App() {

   const sectionRefs = {
    sectionOne: React.useRef(null),
    sectionTwo: React.useRef(null),
    sectionThree: React.useRef(null),
    sectionFour: React.useRef(null),
  };
  const isLandscape = window.innerHeight < window.innerWidth;



  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  if(!isLandscape){
    return (
      <div className='d-flex align-items-center justify-content-center' style={{height : '100vh'}}>
        <h3 className='text-info text-center'>Please rotate your device to landscape and refresh for better experience</h3>
      </div>
    )
  }
  
  return (
    <>
      
      <EarthScene sectionRefs={sectionRefs} />
      <div>
        <SectionOne sectionRefs={sectionRefs}/>
        <SectionTwo sectionRefs={sectionRefs} />
        <SectionThree sectionRefs={sectionRefs} />
        <SectionFour sectionRefs={sectionRefs} />
        <Footer/>
      </div>
      
    </>
    
  );
}

export default App;

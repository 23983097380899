import React from 'react';
import './Navbar.css'

export default function Footer() {
  return (
    <footer style={{position : 'relative',zIndex : 4}} className="bg-dark text-white py-4">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
          <h1 id='brand' style={{fontFamily: 'SpaceFont'}} >Vector <span className = "text-info">Earth</span></h1>
          </div>
          <div className="col-md-3">
            <h4 className='text-info'>Contact</h4>
            
            <span>
              Address: Blurgs Innovations, Goodworks Infinity Park, 2nd main road, Electronic City Phase 1, Bengaluru, 560100.
            </span>
            <span>Phone: 9940110429</span>
          </div>
          <div className="col-md-3">
            <h4 className='text-info'>Follow Us</h4>
            <a href="https://www.linkedin.com/company/blurgs/" style={{textDecoration : 'none',cursor : 'pointer'}} className="text-white">
              <img style={{height : '1.2em'}}  src='/icons/linkedin.png'/>LinkedIn
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

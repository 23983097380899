
import './Navbar.css'

export default function SectionOne({sectionRefs}){

    // if scroll is more than 30vh, brand will be small and move to left top
    // if scroll is less than 30vh, it will be at original position mentioned in the component

    window.addEventListener('scroll',()=>{
        if(window.scrollY > window.innerHeight*0.3){
            document.getElementById('brand').style.fontSize = '1.5em'
            document.getElementById('brand').style.position = 'fixed'
            document.getElementById('brand').style.top = '1em'
            document.getElementById('brand').style.left = '1em'
            // remove mt-5
            document.getElementById('brand').classList.remove('mt-5')
            // background color bluish black
            document.getElementById('brand').style.backgroundColor = 'rgba(0,100,200,0.5)'
        }
        else{
            // <h1 id='brand' style={{fontFamily: 'SpaceFont', fontSize: '3em'}}>Vector <span class="text-info">Earth</span></h1>
            document.getElementById('brand').style.fontSize = '3em'
            // position is block
            document.getElementById('brand').style.position = 'static'
            // for block top and left is not applicable
            document.getElementById('brand').classList.add('mt-5')

            document.getElementById('brand').style.backgroundColor = 'transparent'
        }
    })


    return (
        <div ref={sectionRefs.sectionOne} style={{width : '100vw',height : '95vh'}} >
            <div className='container' style={{height : '100%'}}>
                <div style={{width : '50%',height : '100%'}} className='d-flex flex-column justify-content-between text-light'>
                    <h1 id='brand' style={{fontFamily: 'SpaceFont'}} className='mt-5'>Vector <span className = "text-info">Earth</span></h1>
                    <div>
                        <h1 className='text-info'>10 Years to save our planet.</h1>
                        <p className="text-light mb-5">We need to take action to create a more sustainable future for our planet</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
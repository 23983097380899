import React, { useRef, useEffect } from 'react';
import { useFrame } from 'react-three-fiber';
import { TextureLoader } from 'three';

const fireTextures = [];

for (let i = 0; i < 60; i++) {
    fireTextures.push(`/fire/${i.toString().padStart(2, '0')}.png`);
}

function FireMeshHelper({visible,position}){
    const textureLoader = new TextureLoader();
    const fireMeshRef = useRef();
    const fireTextureIndex = useRef(0);
    

    useFrame(() => {
        fireTextureIndex.current = (fireTextureIndex.current + 1) % fireTextures.length;
        textureLoader.load(fireTextures[fireTextureIndex.current], (texture) => {
            fireMeshRef.current.material.map = texture;
            fireMeshRef.current.material.needsUpdate = true;
        });
    });

    
    return (
        <mesh visible={visible} ref={fireMeshRef} position={position} scale={[0.1, 0.1, 0.1]} rotation={[0,0, Math.PI / 1.8]}>
            <planeGeometry attach="geometry" args={[0.5, 0.5]} />
            <meshBasicMaterial transparent={true}/>
        </mesh>
    )
}

const FireAnimation = ({sectionRefs}) => {
    const textureLoader = new TextureLoader();
    const smokeTexture = textureLoader.load('/smoke.png');

    const [visible, setVisible] = React.useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
        
    }, []);

    let currentSectionId = null 
    const handleScroll = () => {
        let nextSectionId = null;
    
        for (const sectionId in sectionRefs) {
          const sectionRef = sectionRefs[sectionId];
          if (sectionRef.current) {
            const rect = sectionRef.current.getBoundingClientRect();
            if (rect.top <= window.innerHeight * 0.5 && rect.bottom >= window.innerHeight * 0.5) {
              nextSectionId = sectionId;
              if(currentSectionId !== nextSectionId){
          
                if(nextSectionId=='sectionTwo'){
                    console.log('sectionTwo')
                    setTimeout(()=>{
                        if(currentSectionId=='sectionTwo'){
                            setVisible(true);
                        }
                    },1000)
                }
          
                else{
                    console.log('left section two')
                    setVisible(false);
                }
                
         
                currentSectionId = nextSectionId;
              }
    
              break; // No need to continue checking other sections once active section is found
            }
          }
        }
    
    
      };
    

    return (
        <>
            <mesh visible={visible} position={[0.29, 0.04, 4.5]} scale={[0.1, 0.1, 0.1]} rotation={[0,0, Math.PI / 1.8]}>
                <planeGeometry attach="geometry" args={[2, 2.2]} />
                <meshBasicMaterial map = {smokeTexture} transparent={true} />
            </mesh>
            <mesh visible={visible} position={[0.4, -0.25, 4.5]} scale={[0.1, 0.1, 0.1]} rotation={[0,0, Math.PI / 1.8]}>
                <planeGeometry attach="geometry" args={[2, 2.2]} />
                <meshBasicMaterial map = {smokeTexture} transparent={true} />
            </mesh>
            <FireMeshHelper visible={visible}  position={[0.28, 0.04, 4.5]} />
            <FireMeshHelper visible={visible} position={[0.28, 0.06, 4.5]} />
            <FireMeshHelper visible={visible} position={[0.29, 0.07, 4.5]} />
        </>
    );
};

export default FireAnimation;
